import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import './Home.scss';
import {
  ArticleGraphView,
  ArticleReadView,
  ClassifierReadView,
  CustomLocationState,
  OrganisationReadView,
  OrganisationSummaryView
} from '../../types/types';
import ArticleMap from '../map/ArticleMap';
import { useAppShellContext } from '../shell/AppShellContext';
import HeaderBackButton from '../common/HeaderBackButton';
import { getArticleById, getArticleGraphById } from '../../services/Article';
import { useGlobalState } from '../../context/GlobalState';
import OrganisationMap from '../map/OrganisationMap';
import { AxiosError } from 'axios';
import { getOrganisationMapInfo, getOrganisationsSummary } from '../../services/Organisations';
import { getClassifiers } from '../../services/Classifier';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { selectContent } from '../../services/Analytics';
import { getAlternateId } from '../../services/AlternateID';
import { getUrl } from '../../services/URLs';
import { useHomeContext } from '../../context/HomeContext';
import { getGroups } from '../../services/Groups';
import { addVerificationBadgeToIcon, searchInOrganisationSummaries } from '../../utils/HelperFunctions';

const HomeScreen = () => {
  const params = useParams<{ orgName: string }>();
  const appShellContext = useAppShellContext();
  const globalState = useGlobalState();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [graph, setGraph] = useState<ArticleGraphView>();
  const [organisationSummaries, setOrganisationSummaries] = useState<OrganisationSummaryView[]>();
  const [dataToPass, setDataToPass] = useState<OrganisationSummaryView[]>();
  const [orgTotalCount, setOrgTotalCount] = useState<number>(0);
  const [showWarning, setShowWarning] = useState<boolean>();
  const [mainOrganisation, setMainOrganisation] = useState<OrganisationReadView>();

  const homeCtx = useHomeContext();

  const fetchData = async (epc: string) => {
    homeCtx.setLoading(true);
    try {
      let _article: ArticleReadView | null = null;
      const isAlternateId = epc.split('-')[0] === 'alternate';
      if (isAlternateId) {
        const _alternateId = await getAlternateId(epc);
        if (_alternateId.entityType === 'Article') {
          _article = await getArticleById(_alternateId.entityId);
        } else if (_alternateId.entityType === 'Custom URL') {
          const url = await getUrl(_alternateId.entityId);
          navigate(url.url);
          return;
        }
        selectContent({ content_type: 'article_alternate_id', content_id: epc });
      } else {
        if (isNaN(Number(epc))) {
          toast.error('Invalid EPC number!');
          return;
        }
        _article = await getArticleById(Number(epc));
        selectContent({ content_type: 'article_epc', content_id: epc });
      }
      if (!_article) {
        toast.error('No article found!');
        return;
      }
      if (_article.disabled) {
        setShowWarning(true);
      }
      if (_article.customIcon || _article.customUiColor) {
        globalState.setTheme({ primaryColor: _article.customUiColor, logo: _article.customIcon });
      }
      if (_article.urlAlias) {
        navigate(_article.urlAlias);
        return;
      }
      const _org = await getOrganisationMapInfo(`${_article.organisationId}`);
      setMainOrganisation(_org);
      const _graph = await getArticleGraphById(_article.id);
      setGraph(_graph);
    } catch (e) {
      toast.error('Fel QR-kod!', { autoClose: 3000 });
      setGraph(undefined);
    } finally {
      homeCtx.setLoading(false);
    }
  };

  //___ APPLY THE FILTERS HERE____

  const generateCategoriesWithBlueTick = async (data: ClassifierReadView[]) => {
    const newData = await Promise.all(
      data.map(async (d) => {
        const verifiedIcon = await addVerificationBadgeToIcon(d.icon);
        return { ...d, verifiedIcon };
      })
    );
    return newData;
  };
  const fetchOrganisationSummaries = async () => {
    const data = await getOrganisationsSummary();
    setOrganisationSummaries(data.records);
    setDataToPass(data.records);
    setOrgTotalCount(data.records.length);
    const _categories = await getClassifiers();
    const _categoriesWithBlueTick = await generateCategoriesWithBlueTick(_categories.records);
    homeCtx.setClassifiers([..._categoriesWithBlueTick]);
    const _groups = await getGroups();
    homeCtx.setGroups(_groups.records);
  };

  useEffect(() => {
    const epc = searchParams.get('epc');
    if (epc) {
      fetchData(epc);
    } else {
      globalState.resetTheme();
      setGraph(undefined);
      if (homeCtx.classifiers.length === 0) {
        try {
          homeCtx.setLoading(true);
          fetchOrganisationSummaries().finally(() => homeCtx.setLoading(false));
        } catch (e) {
          globalState.handleResponseError(e as AxiosError);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    const state = location.state as CustomLocationState;
    if (state && state.prevPage) {
      appShellContext.setHeaderLeft(
        <HeaderBackButton onClick={() => navigate(location.state.prevPage, { replace: true })} />
      );
    } else {
      appShellContext.setHeaderLeft(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);
  const applyCategoryFilter = useCallback(
    (categoryIds: number[]): boolean => {
      const filteredCategories = [
        ...homeCtx.filteredMainCategories,
        ...homeCtx.filteredSubCategories,
        ...homeCtx.filteredGroups
      ];
      return filteredCategories.every(
        (filteredCategory) => categoryIds !== null && categoryIds.includes(filteredCategory)
      );
    },
    [homeCtx.filteredMainCategories, homeCtx.filteredSubCategories, homeCtx.filteredGroups]
  );

  useEffect(() => {
    const filteredCategories = [
      ...homeCtx.filteredMainCategories,
      ...homeCtx.filteredSubCategories,
      ...homeCtx.filteredGroups
    ];
    if (homeCtx.searchQuery || filteredCategories.length > 0) {
      const filtered = searchInOrganisationSummaries(organisationSummaries ?? [], homeCtx.searchQuery ?? '').filter(
        (organisation) => applyCategoryFilter(organisation.classifierIds)
      );

      setDataToPass([...filtered]);
    } else {
      setDataToPass(organisationSummaries ? [...organisationSummaries] : []);
    }
  }, [
    applyCategoryFilter,
    homeCtx.filteredGroups,
    homeCtx.filteredMainCategories,
    homeCtx.filteredSubCategories,
    homeCtx.searchQuery,
    organisationSummaries
  ]);

  return graph ? (
    <>
      <div className='warning-container'>
        <Dialog open={showWarning ?? false} onClose={() => setShowWarning(false)}>
          <DialogTitle className='warning-dialog-title'>{'Viktig Information!'}</DialogTitle>
          <DialogContent className='warning-dialog-content' style={{ marginTop: '10px' }}>
            <DialogContentText>{'Innehållet i skannad förpackningar har återkallats av leverantör.'}</DialogContentText>
          </DialogContent>
          <DialogActions className='warning-dialog-content'>
            <Button onClick={() => setShowWarning(false)} variant='contained' color='error'>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <ArticleMap loading={homeCtx.loading} data={graph} organisation={mainOrganisation} />
    </>
  ) : (
    <>
      <OrganisationMap data={dataToPass} initialSearch={params.orgName} max={orgTotalCount} />
    </>
  );
};
export default HomeScreen;
