import { GoogleMap, InfoBox, Marker, MarkerF, OverlayView, Polyline, useJsApiLoader } from '@react-google-maps/api';

import React, { useCallback, useEffect, useState } from 'react';
import { mapStyle } from './MapStyle';
import './Map.scss';
import Box from '@mui/material/Box';
import { IconButton, Typography } from '@mui/material';
import MapOverlay from './MapOverlay';
import { ArticleGraphView, ArticleReadView, MAP_LIBRARIES, OrganisationReadView } from '../../types/types';

import markerPerson from '../../utils/img/markerPerson.png';
import markerPersonWhite from '../../utils/img/markerPersonWhite.png';
import {
  addVerificationBadgeToIcon,
  getDistanceBetween,
  getMiddlePosition,
  getPinSize,
  useWindowSize
} from '../../utils/HelperFunctions';
import CloseIcon from '@mui/icons-material/HighlightOff';
import { grey } from '@mui/material/colors';
import { ClusterIconStyle } from '@react-google-maps/marker-clusterer';
import ComponentLoader, { componentLoaderStyles } from '../common/componentLoader/ComponentLoader';
import { ENV_VARIABLES } from '../../config';
import { useLocation } from 'react-router-dom';
import MapInfoBox from '../common/MapInfoBox';
import { toast } from 'react-toastify';

type Props = {
  loading: boolean;
  data?: ArticleGraphView;
  disableOverLay?: boolean;
  organisation?: OrganisationReadView;
};
type ArticlesMapIcons = {
  id: number;
  icon: string;
  verifiedIcon: string;
};
const ArticleMap = (props: Props) => {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [selectedItem, setSelectedItem] = useState<ArticleReadView>();
  const [satellite, setSatellite] = useState<boolean>(false);
  const [userPosition, setUserPosition] = useState<GeolocationCoordinates>();

  const [isStreetView, setIsStreetView] = useState<boolean>(false);
  const [zoomLevel, setZoomLevel] = useState<number>(7);
  const [streetView, setStreetView] = useState<boolean>(false);
  const [showDistances, setShowDistances] = useState<boolean>(true);
  const [showUserTooltip, setShowUserTooltip] = useState<boolean>(false);
  const [mapIcons, setMapIcons] = useState<{ [key: number]: ArticlesMapIcons }>();
  const [loading, setLoading] = useState(true);

  const { isMobileDevice } = useWindowSize();

  const location = useLocation();

  useEffect(() => {
    const id = navigator.geolocation.watchPosition(
      (position) => {
        setUserPosition(position.coords);
      },
      (error) => {
        console.log('Position watch error:', error);
      },
      { enableHighAccuracy: false, timeout: 5000, maximumAge: 0 }
    );
    return () => {
      navigator.geolocation.clearWatch(id);
    };
  }, []);

  const { isLoaded: isMapLoaded, loadError: mapLoadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: ENV_VARIABLES.googleMapsApiKey!,
    libraries: MAP_LIBRARIES
  });

  const onLoad = useCallback(
    (map: google.maps.Map) => {
      let defaultLocation = { lat: 59.2781126, lng: 15.1674353 };
      //Set the bounds to the furthest north and south points on the map in order to avoid zooming out or panning further than those bounds
      const maxBounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(-85, -180),
        new google.maps.LatLng(85, 180)
      );

      const zoomString = new URLSearchParams(location.search).get('zoom');
      var zoom;
      if (zoomString !== null) {
        zoom = parseInt(zoomString);
      }
      map.setOptions({
        center: defaultLocation,
        zoom: Number.isNaN(zoom) ? 7 : zoom ?? 7,
        controlSize: 30,
        restriction: {
          latLngBounds: maxBounds,
          strictBounds: true
        },
        fullscreenControl: false,
        mapTypeControl: false,
        styles: mapStyle
      });
      map.setTilt(45);

      map.getStreetView().addListener('visible_changed', () => {
        setStreetView(map.getStreetView().getVisible());
        console.log(`street viewed? ${streetView}`);
      });

      map.getStreetView().addListener('visible_changed', () => {
        setStreetView(map.getStreetView().getVisible());
        console.log(`street viewed? ${streetView}`);
      });

      setMap(map);
    },
    [streetView, location]
  );

  const onUnmount = useCallback((map: google.maps.Map) => {
    setMap(null);
  }, []);

  useEffect(() => {
    if (map) {
      map.setOptions({ mapTypeId: satellite ? google.maps.MapTypeId.HYBRID : google.maps.MapTypeId.ROADMAP });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [satellite]);

  useEffect(() => {
    let streetViewChangeListener: google.maps.MapsEventListener | null = null;
    if (map) {
      streetViewChangeListener = google.maps.event.addListener(map.getStreetView(), 'visible_changed', () => {
        setIsStreetView(map.getStreetView().getVisible());
      });
    }
    return () => {
      if (streetViewChangeListener && map) google.maps.event.removeListener(streetViewChangeListener);
    };
  }, [map]);

  useEffect(() => {
    if (map !== null && !props.loading) {
      if (props.data) {
        const selectedArticle = props.data.articles.find((article) => article.id === props.data?.id) ?? null;
        if (selectedArticle) {
          map.setCenter({ lat: selectedArticle.lat, lng: selectedArticle.lng });
        }
      } else {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            if (position) {
              const currentLocation = { lat: position.coords.latitude, lng: position.coords.longitude };
              map.setCenter(currentLocation);
            }
          },
          (error) => console.warn('Browser location error', error)
        );
      }
    }
  }, [map, props.data, props.loading]);

  useEffect(() => {
    const generateMapIcons = async () => {
      const _mapIconsEntries = await Promise.all(
        props.data!.articles.map(async (article) => {
          const verifiedIcon = await addVerificationBadgeToIcon(article.mapIcon);
          return { id: article.id, icon: article.mapIcon, verifiedIcon };
        })
      );
      const _mapIcons = _mapIconsEntries.reduce((acc: { [key: number]: ArticlesMapIcons }, item) => {
        acc[item.id] = item;
        return acc;
      }, {});
      setMapIcons(_mapIcons);
    };

    if (props.data) {
      setShowDistances(props.data.articles.length <= 10);
    }
    if (props.data && props.data.articles.length > 0) {
      setLoading(true);
      generateMapIcons().finally(() => {
        setLoading(false);
      });
    } else {
      setMapIcons(undefined);
    }
  }, [props.data]);

  const getMapIcon = useCallback(
    (a: ArticleReadView): string => {
      if (!mapIcons) {
        toast.error('Något fel med ikoner');
        return '';
      }
      if (a.customerCardEnabled) {
        return mapIcons[a.id].verifiedIcon;
      }

      return mapIcons[a.id].icon;
    },
    [mapIcons]
  );

  return mapLoadError ? (
    <Box sx={componentLoaderStyles.fullCentered}>
      <Typography variant='h6' color={grey[100]}>
        Map Loading Error!
      </Typography>
      <Typography variant='body1' color={grey[100]}>
        Please refresh the page!
      </Typography>
    </Box>
  ) : !isMapLoaded || loading ? (
    <ComponentLoader />
  ) : (
    <>
      {isMobileDevice && selectedItem && (
        <MapInfoBox
          article={selectedItem}
          onClose={() => setSelectedItem(undefined)}
          isMobileDevice={isMobileDevice}
          parentOrganisationId={
            props.data ? props.data.articles.find((a) => a.id === props.data?.id)?.organisationId : undefined
          }
        />
      )}
      <GoogleMap
        id='react-google-maps'
        mapContainerClassName={'google-map-container-style'}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onZoomChanged={() => {
          map && setZoomLevel(map.getZoom()!);
        }}
      >
        {props.data &&
          props.data.articles.map((article, index) => (
            <Marker
              key={`${article.id}-${index}`}
              position={{ lat: article.lat, lng: article.lng }}
              zIndex={props.data && article.id === props.data.id ? 1 : 0} // move selected article to the front
              animation={props.data && article.id === props.data.id ? google.maps.Animation.BOUNCE : undefined}
              icon={{
                scaledSize: isStreetView
                  ? new google.maps.Size(65, 75)
                  : getPinSize(location, props.organisation && props.organisation.customerCardEnabled ? true : false),
                url: getMapIcon(article)
              }}
              onClick={() => setSelectedItem(article)}
            >
              {selectedItem && selectedItem.id === article.id && !isMobileDevice && (
                <MapInfoBox
                  article={article}
                  onClose={() => setSelectedItem(undefined)}
                  isMobileDevice={isMobileDevice}
                  parentOrganisationId={
                    props.data ? props.data.articles.find((a) => a.id === props.data?.id)?.organisationId : undefined
                  }
                />
              )}
            </Marker>
          ))}
        {userPosition && (
          <MarkerF
            position={{ lat: userPosition!.latitude, lng: userPosition!.longitude }}
            icon={{
              url: satellite || isStreetView ? markerPersonWhite : markerPerson,
              scaledSize: new google.maps.Size(40, 40)
            }}
            onClick={() => {
              setShowUserTooltip(true);
            }}
          >
            {showUserTooltip && (
              <InfoBox
                options={{
                  enableEventPropagation: true,
                  closeBoxURL: '',
                  boxStyle: {
                    boxShadow: ' 8px 8px 41px -15px rgba(0,0,0,0.75)',
                    backgroundColor: 'rgba(255,255,255,0)'
                  }
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'white',
                    padding: '1.5rem .5rem',
                    borderRadius: '1rem',
                    minWidth: isMobileDevice ? 'calc(window.innerWidth - 2rem)' : '25%'
                  }}
                >
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography variant='body2' align='left' noWrap>
                      Du är här
                    </Typography>
                  </Box>
                  <Box>
                    <IconButton
                      aria-label='close button'
                      onClick={() => setShowUserTooltip(false)}
                      sx={{ padding: 0, marginLeft: 2 }}
                    >
                      <CloseIcon sx={{ color: grey[600] }} fontSize='small' />
                    </IconButton>
                  </Box>
                </Box>
              </InfoBox>
            )}
          </MarkerF>
        )}
        {props.data &&
          props.data.articles.map((article, index) => {
            return article.sourceArticleIds.map((id) => {
              const source = { lat: article.lat, lng: article.lng };
              const destArticle = props.data!.articles.find((a) => a.id === id);
              const destination = destArticle ? { lat: destArticle.lat, lng: destArticle.lng } : null;
              const distance = source && destination ? getDistanceBetween(source, destination) : null;
              return source && destination ? (
                <React.Fragment key={`${article.id}-${id}-${index}`}>
                  <Polyline
                    options={{
                      strokeColor: '#4468E1',
                      strokeWeight: 1,
                      icons: [
                        {
                          icon: { path: google.maps.SymbolPath.BACKWARD_OPEN_ARROW },
                          offset: '45%',
                          repeat: zoomLevel > 8 ? '25%' : undefined
                        }
                      ]
                    }}
                    path={[source, destination]}
                  />
                  {showDistances && (
                    <OverlayView
                      position={getMiddlePosition(source, destination)}
                      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                      getPixelPositionOffset={(width, height) => ({ x: -10, y: -10 })}
                    >
                      <Box sx={{ bgcolor: 'secondary.main', borderRadius: '1rem', p: 0.5, marginLeft: -1 }}>
                        <Typography variant='caption' color={'white'}>
                          {distance?.toFixed(0)} Km
                        </Typography>
                      </Box>
                    </OverlayView>
                  )}
                </React.Fragment>
              ) : null;
            });
          })}
        {props.data &&
          userPosition &&
          props.data.articles
            .filter((article) => article.id === props.data?.id)
            .map((article, index) => {
              const userLatLng = { lat: userPosition.latitude, lng: userPosition.longitude };
              const distance = getDistanceBetween(userLatLng, article);
              return (
                <React.Fragment key='user-distance'>
                  <Polyline
                    options={{
                      strokeColor: '#4468E1',
                      strokeOpacity: 0.0,
                      icons: [
                        {
                          icon: {
                            path: google.maps.SymbolPath.CIRCLE,
                            fillOpacity: 1,
                            strokeOpacity: 1,
                            scale: 1.5
                          },
                          offset: '19px',
                          repeat: '20px'
                        }
                      ]
                    }}
                    path={[userLatLng, article]}
                  />
                  <OverlayView
                    position={getMiddlePosition(userLatLng, article)}
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    getPixelPositionOffset={(width, height) => ({ x: -10, y: -10 })}
                  >
                    <Box sx={{ bgcolor: 'secondary.main', borderRadius: '1rem', p: 0.5, marginLeft: -1 }}>
                      <Typography variant='caption' color={'white'}>
                        {distance?.toFixed(0)} Km
                      </Typography>
                    </Box>
                  </OverlayView>
                </React.Fragment>
              );
            })}
        <MapOverlay
          map={map}
          loading={props.loading}
          satellite={satellite}
          setSatellite={setSatellite}
          showDistances={showDistances}
          setShowDistances={setShowDistances}
          disableOverlay={props.disableOverLay}
        />
      </GoogleMap>
    </>
  );
};

export default ArticleMap;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MarkerClustererStyles: ClusterIconStyle[] = [
  { height: 70, width: 70, url: require('../../utils/img/m1.png'), textColor: 'white', textSize: 12 },
  { height: 75, width: 75, url: require('../../utils/img/m2.png'), textColor: 'black', textSize: 12 },
  { height: 80, width: 80, url: require('../../utils/img/m3.png'), textColor: 'white', textSize: 13 },
  { height: 85, width: 85, url: require('../../utils/img/m4.png'), textColor: 'black', textSize: 13 },
  { height: 90, width: 90, url: require('../../utils/img/m5.png'), textColor: 'white', textSize: 14 }
];
