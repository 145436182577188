import { createContext, useContext, useEffect, useState } from 'react';
import AppShell from './AppShell';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { useCookies } from 'react-cookie';

type AppShellContextType = {
  headerLeft: JSX.Element | null;
  setHeaderLeft: React.Dispatch<React.SetStateAction<JSX.Element | null>>;
};
const initialValue: AppShellContextType = { headerLeft: null, setHeaderLeft: () => {} };
const AppShellContext = createContext<AppShellContextType>(initialValue);
const AppShellProvider = ({ children }: { children?: any }) => {
  const [headerLeft, setHeaderLeft] = useState<JSX.Element | null>(null);

  return (
    <AppShellContext.Provider value={{ headerLeft, setHeaderLeft }}>
      <AppShell />
      <RouteTracker />
    </AppShellContext.Provider>
  );
};

export default AppShellProvider;
export const useAppShellContext = () => useContext(AppShellContext);

const RouteTracker = () => {
  const location = useLocation();
  const [cookies] = useCookies(['consent']);

  const sendMinimalPageView = async (path: string, isMinimal: boolean) => {
    ReactGA.send({
      hitType: 'pageview',
      page: path,
      anonymizeIp: isMinimal // Ensures IP is anonymized
    });
  };
  useEffect(() => {
    const userConsent = cookies['consent'];
    if (userConsent === 'accepted') {
      sendMinimalPageView(location.pathname + location.search, false);
    } else {
      sendMinimalPageView(location.pathname + location.search, true);
    }
  }, [cookies, location]);

  return null;
};
