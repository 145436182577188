import ReactDOM from 'react-dom/client';
import GlobalState from './context/GlobalState';
import ReactGA from 'react-ga4';
import App from './App';
//____ROBOTO FONTS_____
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import 'react-toastify/dist/ReactToastify.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);
// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;

  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

const gaTrackingId = 'G-PVQXK158EH';

function Root() {
  return (
    <GlobalState>
      <App />
    </GlobalState>
  );
}

root.render(<Root />);

serviceWorkerRegistration.register({
  onUpdate: async (registration) => {
    // We want to run this code only if we detect a new service worker is
    // waiting to be activated.
    // Details about it: https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle
    if (registration && registration.waiting) {
      await registration.unregister();
      // Makes Workbox call skipWaiting()
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      // Once the service worker is unregistered, we can reload the page to let
      // the browser download a fresh copy of our app (invalidating the cache)
      window.location.reload();
    }
  }
});

export const sendToGoogleAnalytics = () => {
  ReactGA.initialize(gaTrackingId);
};

export const initializeMinimalGA = () => {
  ReactGA.initialize(gaTrackingId, {
    gaOptions: {
      anonymizeIp: true, // Ensures IP anonymization
      storage: 'none',
      storeGac: false
    }
  });

  // Disable Google Analytics features that collect user data
  ReactGA.gtag('set', 'allow_ad_personalization_signals', false);
  ReactGA.gtag('set', 'allow_google_signals', false);
};
